import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Link as Link1 } from "react-scroll";
import { TypeAnimation } from "react-type-animation";
import CountUp from "react-countup";

// the hook
import { useTranslation } from "react-i18next";
import { use } from "i18next";

// import PreLoader from "./PreLoader";
export default function HeroOne() {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="relative pt-28 overflow-hidden flex max-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: "url('/images/slider/1.png')",
        // height: "100vh",
      }}
      id="home"
    >
      <div className="absolute" id="overlay"></div>
      {/* <div className="heroSession"> */}
      <div className="containerHero">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px] ">
          <div>
            <h4
              className={`lg:text-[32px] lg:leading-normal leading-normal mb-4 ${
                i18n.resolvedLanguage === "zh"
                  ? "font-reborn text-5xl"
                  : "text-3xl font-bold "
              }`}
            >
              {t("about.hey")} <br />
              {/* {renderComponent()} */}
              {i18n.resolvedLanguage === "en" && (
                <div style={{ height: "95px" }}>
                  <TypeAnimation
                    sequence={[
                      "Registered Social Worker",
                      3000, // Waits 3s
                      "Counselor",
                      3000,
                      "Parenting Consultant",
                      3000,
                      "Neuro Linguistic Program Practitioner",
                      3000,
                      "Pre- and post-marital counselor",
                      3000,
                      "Zentangle Registered Tutor (U.S.)",
                      3000,
                      "Yoga Teacher",
                      3000,
                      "Adventure Coach",
                      3000,
                    ]}
                    wrapper="span"
                    speed={50}
                    className={`typewrite text-amber-500  `}
                    repeat={Infinity}
                  />
                </div>
              )}
              {i18n.resolvedLanguage === "zh" && (
                <div style={{ height: "125px" }}>
                  <TypeAnimation
                    sequence={[
                      // "註冊社工",
                      // 3000, // Waits 3s
                      // "輔導員",
                      // 3000,
                      // "家長管教諮詢師",
                      // 3000,
                      "神經語言程式學執行師",
                      3000,
                      // "婚前婚後評估執行師",
                      // 3000,
                      // "美國註冊禪繞畫導師",
                      // 3000,
                      // "瑜伽導師",
                      // 3000,
                      "歷奇導師",
                      3000,
                    ]}
                    wrapper="span"
                    speed={50}
                    className={`typewrite text-amber-500 lg_992:h-[250px] `}
                    repeat={Infinity}
                  />
                </div>
              )}
              <span
                className="typewrite text-amber-500"
                data-period="2000"
                data-type='[ "Dennis Scott", "Website Designer", "Web Developer", "UI/UX Designer" ]'
              >
                <span className="wrap"></span>{" "}
              </span>
            </h4>
            <p
              className={`text-slate-400 max-w-xl ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              } `}
            >
              {t("about.p.3")} <br />
              {t("about.p.2")} <br />
              {t("about.p.4")} <br />
              {t("about.p.1")} <br />
            </p>
          </div>

          <div className="relative">
            <img src="/images/hero/hero-kaka.png" alt="" />

            <div className="absolute lg:bottom-20 md:bottom-10 bottom-20 ltr:md:-left-5 ltr:left-2 rtl:md:-right-5 rtl:right-2 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-43 text-center">
              <h6
                className={`${
                  i18n.resolvedLanguage === "zh"
                    ? "font-reborn text-xl "
                    : "font-semibold"
                }`}
              >
                {t("about.Youth-Work")}
              </h6>
              {/* {i18n.resolvedLanguage === "zh" && (
                <h6 className="font-medium mb-0  text-slate-400 font-reborn text-lg">
                  <CountUp
                    start={10}
                    className="counter-value font-reborn text-lg"
                    end={20}
                    duration={9}
                  />
                  {t("about.Years")}
                  {t("about.Experience")}
                </h6>
              )}

              {i18n.resolvedLanguage === "en" && (
                <h6 className="font-medium mb-0  text-slate-400">
                  <CountUp
                    start={10}
                    className="counter-value"
                    end={20}
                    duration={9}
                  />
                  {t("about.Years")}
                  <br /> {t("about.Experience")}
                </h6>
              )} */}
            </div>

            {/* <div className="absolute lg:top-80 md:top-56 top-48 ltr:md:-right-0 ltr:right-2 rtl:md:-left-0 rtl:left-2 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
              <h6
                className={`${
                  i18n.resolvedLanguage === "zh"
                    ? "font-reborn text-xl "
                    : "font-semibold"
                }`}
              >
                {t("about.Family")}
              </h6>

       
              {i18n.resolvedLanguage === "zh" && (
                <h6 className="font-medium mb-0  text-slate-400 font-reborn text-lg">
                  <CountUp
                    start={7}
                    className="counter-value"
                    end={10}
                    duration={9}
                  />
                  {t("about.Years")}
                  {t("about.Experience")}
                </h6>
              )}

              {i18n.resolvedLanguage === "en" && (
                <h6 className="font-medium mb-0  text-slate-400">
                  <CountUp
                    start={7}
                    className="counter-value"
                    end={10}
                    duration={9}
                  />
                  {t("about.Years")}
                  <br /> {t("about.Experience")}
                </h6>
              )}
            </div> */}
          </div>
        </div>
        <div className="absolute block w-full bottom-[0px] z-1 left-0">
          <Link1 to="about">
            <i className=" mdi mdi-arrow-down absolute top-0 left-0 right-0 text-center inline-flex items-center justify-center rounded-full bg-white dark:bg-slate-900 h-12 w-12 mx-auto shadow-md dark:shadow-gray-800"></i>
          </Link1>
        </div>
      </div>
    </section>
  );
}
