import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(Backend)

  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "zh",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      //網頁載入時去下載語言檔的位置
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },

    // 預設語言
    lng: "zh",
    // resources: {
    //   en: {
    //     translation: {
    //       // here we will place our translations...
    //       navBar: {
    //         about: "About Me",
    //         services: "Services",
    //         projects: "Projects",
    //         blogs: "Blogs",
    //         contact: "Contact Me",
    //         album: "Album",
    //       },
    //     },
    //   },

    //   zh: {
    //     translation: {
    //       // here we will place our translations...
    //       navBar: {
    //         about: "關於",
    //         services: "服務",
    //         projects: "工作",
    //         blogs: "日誌",
    //         contact: "聯絡",
    //         album: "相冊",
    //       },
    //     },
    //   },
    // },
  });

export default i18n;
