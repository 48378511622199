import React, { memo, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import emailjs from "@emailjs/browser";
import { Typography, Button } from "@material-tailwind/react";
import CounselingTestimonial from "../testimonials/CounselingTestimonial";
import ContactForm from "../ContactForm"; // Adjust the path as necessary

// hook
import { useTranslation, Trans } from "react-i18next";

import { z } from "zod";

// Define the Zod schema for form validation
const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  subject: z.string().min(1, "Subject is required"),
  message: z.string().min(1, "Message is required"),
});

const Counseling = memo(({ open, close, setOpen }) => {
  const { t, i18n } = useTranslation();
  const service_id = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const template_id = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const public_key = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
  // Define the form reference and state
  const form = useRef();

  const [showModal, setShowModal] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: i18n.resolvedLanguage === "zh" ? "輔導查詢" : "Counseling Inquiry",
    message:
      i18n.resolvedLanguage === "zh"
        ? "你好，黃姑娘我想查詢有關輔導的價錢和時間。我的聯絡號碼是：8888 - 8888。謝謝！"
        : "Hello, Miss Wong. I would like to inquire about the prices and schedule for counseling. My contact number is: 8888 - 8888. Thank you!",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      subject:
        i18n.resolvedLanguage === "zh" ? "輔導查詢" : "Counseling Inquiry",
      message:
        i18n.resolvedLanguage === "zh"
          ? "你好，黃姑娘我想查詢有關輔導的價錢和時間。我的聯絡號碼是：8888 - 8888。謝謝！"
          : "Hello, Miss Wong. I would like to inquire about the prices and schedule for counseling. My contact number is: 8888 - 8888. Thank you!",
    }));
  }, [i18n.resolvedLanguage]);

  // Define the sendEmail function
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("counseling formValues", formValues);

    // Validate form values using Zod schema
    const validation = formSchema.safeParse(formValues);

    if (!validation.success) {
      // Handle validation errors
      console.log("Validation errors:", validation.error.format());
      setErrors(validation.error.format()); // Update errors state
      return;
    }
    emailjs.sendForm(service_id, template_id, form.current, public_key).then(
      (result) => {
        // console.log(result.text);
        setShowModal(true);
        setFormValues({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      },
      (error) => {
        console.log(error.text);
      }
    );
    setErrors({}); // Clear errors if validation passes
    // Proceed with sending email
  };

  return (
    <>
      <TEModal show={open} setShow={setOpen} scrollable>
        <TEModalDialog size="xl">
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5
                className={`leading-normal text-neutral-800 dark:text-neutral-200
                ${
                  i18n.resolvedLanguage === "zh"
                    ? "font-reborn ml-3 text-2xl"
                    : "text-xl font-medium"
                }
              
              `}
              >
                {t("popUp.counseling.title")}
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => close(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            <TEModalBody
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <div className="popContainer">
                <div className="mb-4 flex justify-center">
                  <img
                    src="/images/service/counseling.jpg"
                    className="h-auto max-w-full rounded-lg mt-4"
                    alt=""
                  />
                </div>
                <ul>
                  <li key="c1">
                    <Trans i18nKey="popUp.counseling.p1">
                      Mainly use <strong>Solution-Focused Therapy</strong>,
                      which is <strong>QUICK</strong> and <strong>FOCUS</strong>{" "}
                      as advantages, with combination of different counseling
                      element
                    </Trans>
                  </li>

                  <li key="c2" className="mt-3">
                    {t("popUp.counseling.deal-with")}
                    <ul className="ml-6 list-disc">
                      <li style={{ display: "none" }}></li>
                      <li key="c3">{t("popUp.counseling.1")}</li>
                      <li key="c4">{t("popUp.counseling.2")}</li>
                      <li key="c5">{t("popUp.counseling.3")}</li>
                      <li key="c6">{t("popUp.counseling.4")}</li>
                      <li key="c7">{t("popUp.counseling.5")}</li>
                      <li key="c8">{t("popUp.counseling.6")}</li>
                    </ul>
                  </li>
                </ul>

                <ul className="mt-3">
                  <li></li>
                  <li key="c11">{t("popUp.counseling.7")}</li>
                  <li key="c12">{t("popUp.counseling.8")}</li>
                  <li key="c13">{t("popUp.counseling.9")}</li>
                </ul>
                <CounselingTestimonial />
                <ContactForm
                  form={form}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  sendEmail={sendEmail}
                  errors={errors} // Pass errors as a prop
                />
              </div>
            </TEModalBody>
            <TEModalFooter
              className={`   ${
                i18n.resolvedLanguage === "zh" ? "font-reborn text-lg" : ""
              }`}
            >
              <TERipple rippleColor="light" className="mr-2">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border px-4 rounded font-reborn"
                  onClick={sendEmail}
                >
                  {i18n.resolvedLanguage === "zh" ? (
                    <Typography className="text-gray-600 font-reborn text-sm">
                      {t("contact.send-message")}
                    </Typography>
                  ) : (
                    t("contact.send-message")
                  )}
                </Button>
              </TERipple>
              <TERipple rippleColor="light" className="mr-2">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border px-4 rounded font-reborn"
                  onClick={() => close(false)}
                >
                  {i18n.resolvedLanguage === "zh" ? (
                    <Typography className="text-gray-600 font-reborn text-sm">
                      {t("popUp.counseling.close")}
                    </Typography>
                  ) : (
                    t("popUp.counseling.close")
                  )}
                </Button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
      {/* <!-- Modal --> */}
      <TEModal key="email_modal" show={showModal} setShow={setShowModal}>
        <TEModalDialog>
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                {t("contact.message")}
              </h5>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody>
              {/* Thank you for reaching out. Your email has been successfully sent. */}
              {t("contact.success")}
            </TEModalBody>
            <TEModalFooter>
              <TERipple rippleColor="light">
                <Button
                  variant="outlined"
                  size="sm"
                  className="border px-4 rounded font-reborn"
                  onClick={() => setShowModal(false)}
                >
                  {i18n.resolvedLanguage === "zh" ? (
                    <Typography className="text-gray-600 font-reborn text-sm">
                      {t("contact.close")}
                    </Typography>
                  ) : (
                    t("contact.close")
                  )}
                </Button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
});

export default Counseling;
