import React from "react";

import TinySlider from "tiny-slider-react";

import "../../../node_modules/tiny-slider/dist/tiny-slider.css";

// hook
import { useTranslation, Trans } from "react-i18next";

const settings = {
  lazyload: true,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 12,
  responsive: {
    1530: {
      items: 2,
    },
    992: {
      items: 2,
    },

    767: {
      items: 1,
    },

    320: {
      items: 1,
    },
  },
};
export default function PremaritalCounselingTestimonial() {
  const { t, i18n } = useTranslation();

  const all_review = [
    {
      name: "Mr Chong",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.9"),
    },
    {
      name: " Mr Mak",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: t("client.reviews.10"),
    },
  ];

  const all_review_zh = [
    {
      name: "莊先生",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "一直都唔知道原來婚前輔導嘅重要性。呢個報告好全面，加埋輔導之後將一直以來都埋咗喺心裏面嘅不滿都講咗出嚟，再諗之後點面對。慶幸喺結婚前清一次，多謝未婚妻提議黎見輔導！",
    },
    {
      name: "麥先生",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: "結婚之後一直都儲埋好多不滿，直到呢件事發生！一直都原諒唔到。經過呢次輔導，我明白到點解我咁執着。而佢亦都真正理解我嘅諗法，同埋反思到自己嘅問題！我哋而家關係開始一步步改變。",
    },
  ];

  return (
    <section
      // className="relative md:py-24 py-16 bg-green-400 dark:bg-slate-800"
      className="relative py-16"
      id="testi"
    >
      <div className="container">
        <div className="grid grid-cols-1 text-center">
          <h3
            className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal
           ${i18n.resolvedLanguage === "zh" ? "font-reborn" : "font-semibold"}
          `}
          >
            {t("client.title")}
          </h3>
        </div>

        {i18n.resolvedLanguage === "zh" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review_zh.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-1 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>

                        <p className="text-slate-400 text-[18px] font-reborn">
                          {item.desc}
                        </p>

                        <p className="text-slate-400 text-[18px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none  text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}

        {i18n.resolvedLanguage === "en" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>
                        <p className="text-slate-400 text-[15px]">
                          {item.desc}
                        </p>
                        <p className="text-slate-400 text-[15px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
