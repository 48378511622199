import React, { useRef, useState, useEffect } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
// hook
import { useTranslation } from "react-i18next";

import { Element, animateScroll as scroll } from "react-scroll";
export default function GetInTouch() {
  const { t, i18n } = useTranslation();
  const form = useRef();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const service_id = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const template_id = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const public_key = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  useEffect(() => {
    const scrollToBottom = () => {
      const options = {
        duration: 200,
        smooth: true,
      };
      scroll.scrollToBottom(options);
    };

    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem && location.hash.slice(1).toString() === "contactMe") {
        scrollToBottom();
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(service_id, template_id, form.current, public_key).then(
      (result) => {
        // console.log(result.text);
        setShowModal(true);
        setFormValues({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <section
      className="md:py-36 py-36 bg-green-400 dark:bg-slate-800"
      // className="relative md:py-24 py-16 bg-green-400 dark:bg-slate-800"
      id="contactMe"
    >
      <div className="brush_contact_1" data-wow-duration="1s">
        <img src="/images/bg/brushes/contact/1.png" alt="1" />
      </div>
      {/* <!-- Modal --> */}
      <TEModal key="email_modal" show={showModal} setShow={setShowModal}>
        <TEModalDialog>
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                {t("contact.message")}
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody>
              {/* Thank you for reaching out. Your email has been successfully sent. */}
              {t("contact.success")}
            </TEModalBody>
            <TEModalFooter>
              <TERipple rippleColor="light">
                <button
                  type="button"
                  className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                  onClick={() => setShowModal(false)}
                >
                  {t("contact.close")}
                </button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3
            className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
              i18n.resolvedLanguage === "zh" ? "font-reborn  " : "font-semibold"
            }  `}
          >
            {t("contact.title")}
          </h3>
        </div>

        <div className="grid grid-cols-6 gap-4">
          <div className="col-start-2 col-span-4">
            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
              <form ref={form} onSubmit={sendEmail}>
                <div className="grid lg:grid-cols-12 lg:gap-5">
                  <div className="lg:col-span-6 mb-5">
                    <input
                      value={formValues.name}
                      onChange={(e) =>
                        setFormValues({ ...formValues, name: e.target.value })
                      }
                      key="user_name"
                      name="user_name"
                      id="name"
                      type="text"
                      className={`
                      ${i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""} 
                      form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
                      placeholder={t("contact.name")}
                    />
                  </div>

                  <div className="lg:col-span-6 mb-5 ">
                    <input
                      value={formValues.email}
                      onChange={(e) =>
                        setFormValues({ ...formValues, email: e.target.value })
                      }
                      key="user_email"
                      name="user_email"
                      id="email"
                      type="email"
                      className={`
                      ${
                        i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
                      } form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
                      placeholder={t("contact.email")}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <input
                      value={formValues.subject}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          subject: e.target.value,
                        })
                      }
                      key="subject"
                      name="subject"
                      id="subject"
                      className={`
                      ${
                        i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
                      } form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
                      placeholder={t("contact.subject")}
                    />
                  </div>

                  <div className="mb-5">
                    <textarea
                      value={formValues.message}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          message: e.target.value,
                        })
                      }
                      key="message"
                      name="message"
                      id="comments"
                      className={`
                      ${
                        i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
                      } form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
                      placeholder={t("contact.message")}
                    ></textarea>
                  </div>
                </div>

                <button
                  type="submit"
                  id="submit"
                  name="send"
                  // className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center"
                  className={`
                  ${
                    i18n.resolvedLanguage === "zh"
                      ? "font-reborn text-lg  "
                      : ""
                  } btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center`}
                >
                  {t("contact.send-message")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
