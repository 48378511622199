import React from "react";
import { Link } from "react-router-dom";

import TinySlider from "tiny-slider-react";
// import "../../../node_modules/tiny-slider/dist/tiny-slider.css";
import "../../../node_modules/tiny-slider/dist/tiny-slider.css";
import { counseling_review, zentangle_review } from "../../Data/data";
// hook
import { useTranslation, Trans } from "react-i18next";
const settings = {
  lazyload: true,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 12,
  responsive: {
    1530: {
      items: 2,
    },
    992: {
      items: 2,
    },

    767: {
      items: 1,
    },

    320: {
      items: 1,
    },
  },
};
export default function ZentangleTestimonial() {
  const { t, i18n } = useTranslation();

  const all_review = [
    {
      name: " Primary School Teacher",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: t("client.reviews.3"),
    },
    {
      name: "Parent",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.4"),
    },

    {
      name: "Elderly",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.5"),
    },
    {
      name: "Corporate Staff",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.6"),
    },

    {
      name: "Pain Patient",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.7"),
    },
  ];

  const all_review_zh = [
    {
      name: "小學老師 (禪繞畫)",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: "冇諗過過度活躍嘅學生都可以坐得低咁耐，仲要勁有創意，感覺到佢自信高咗。",
    },
    {
      name: "家長 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "好難得有自己時間，好集中係自己度，唔使再諗湊仔嘅嘢！",
    },

    {
      name: "長者 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "以前揸開刀，咁耐以來第一次揸筆! 原來好簡單，劃劃下就上手，有時畫到停唔到！",
    },
    {
      name: "公司員工 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "平日番工好大壓力。呢幾日開會專注唔到，點知劃劃下反而專注繼續開會!",
    },

    {
      name: "痛症病人 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "之前關節痛咗好耐，畫嘅時候即刻感覺唔到痛，好神奇！",
    },
  ];

  return (
    <section
      // className="relative md:py-24 py-16 bg-green-400 dark:bg-slate-800"
      className="relative py-16"
      id="testi"
    >
      <div className="container">
        <div className="grid grid-cols-1 text-center">
          <h3
            className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal
           ${i18n.resolvedLanguage === "zh" ? "font-reborn" : "font-semibold"}
          `}
          >
            {t("client.title")}
          </h3>
        </div>
        {i18n.resolvedLanguage === "zh" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review_zh.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-1 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>

                        <p className="text-slate-400 text-[18px] font-reborn">
                          {item.desc}
                        </p>

                        <p className="text-slate-400 text-[18px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none  text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}

        {i18n.resolvedLanguage === "en" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>
                        <p className="text-slate-400 text-[15px]">
                          {item.desc}
                        </p>
                        <p className="text-slate-400 text-[15px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
