import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";
import Index from "./main/index";

import { AnimatePresence } from "framer-motion";
import PreLoader from "./components/PreLoader";

import Footer from "./components/Footer";
import Album from "./Pages/Album";
import PsychologicalDistressTest from "./Pages/self-test/PsychologicalDistressTest";
import PartnershipAssessment from "./Pages/self-test/partnership-assessment/PartnershipAssessment";
// TODO simple scroll for index page
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NotFound from "./NotFound";

function App() {
  return (
    <AnimatePresence>
      {/* <PreLoader /> */}
      <PreLoader key="pre1" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} exact />
          <Route path="/album/" element={<Album />} />
          <Route
            path="/psychological-distress-test"
            element={<PsychologicalDistressTest />}
          />
          <Route path="/assessment" element={<PartnershipAssessment />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;
