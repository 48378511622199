import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Drawer,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Card,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
// ICONS
import { MdArticle } from "react-icons/md";
import { IoPersonCircleSharp } from "react-icons/io5";
import { MdWork } from "react-icons/md";
import { FaPenClip } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdPhotos } from "react-icons/io";
import { FaFileSignature } from "react-icons/fa6";
import { FaChevronCircleDown } from "react-icons/fa";
import { FaCircleChevronRight } from "react-icons/fa6";

import { FaHeartCircleExclamation } from "react-icons/fa6";
import { FaVenusMars } from "react-icons/fa6";
// the hook
import { useTranslation } from "react-i18next";

// ICON

import { IoLanguage } from "react-icons/io5";

// Accessing environment variables using process.env
const chp_ig_link = process.env.REACT_APP_CHP_IG;
const chp_facebook_link = process.env.REACT_APP_CHP_FACEBOOK;
const chp_whatsapp_link = process.env.REACT_APP_CHP_WHATSAPP_LINK;
export default function Navbar() {
  const [openSelfTest, setOpenSelfTest] = useState(0);

  const handleOpenSelfTest = (value) => {
    setOpenSelfTest(openSelfTest === value ? 0 : value);
  };

  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  // const [showCard, setShowCard] = useState(false); // Add state for card visibility

  const location = useLocation();
  const currentPath = location.pathname;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleLinkClick = () => {
      window.scrollTo(0, 0);
    };

    const link = document.getElementById("albumButton");
    link.addEventListener("click", handleLinkClick);

    return () => {
      link.removeEventListener("click", handleLinkClick);
    };
  }, []);
  window.addEventListener("scroll", windowScroll);
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    // console.log(document.documentElement.scrollTop);
    // console.log(currentPath);
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("is-sticky");
      }
    } else {
      if (navbar !== null && currentPath == "/") {
        navbar?.classList.remove("is-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  return (
    <nav className="navbar is-sticky">
      <div className="container flex flex-wrap items-center justify-between">
        <Link to="/" className="navbar-brand md:me-8" href="index.html">
          <img
            src="/images/logo.jpg"
            className="inline-block dark:hidden h-10 md:h-12 w-200"
            alt=""
          />

          {/* <img
            src="/images/logo-light.png"
            className="hidden dark:inline-block"
            alt=""
          /> */}
        </Link>

        <div className="nav-icons flex items-center lg_992:order-2 ms-auto md:ms-8">
          <ul className="list-none menu-social  mb-0" id="navbar-navlist">
            <li className="inline-flex">
              <Menu className=" collapse-btn inline-flex items-center ms-2 text-dark dark:text-white  lg_992:hidden">
                <MenuHandler>
                  <div>
                    <span className="login-btn-primary">
                      <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <IoLanguage width={16} />
                      </span>
                    </span>

                    <span className="login-btn-light">
                      <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <IoLanguage width={16} />
                      </span>
                    </span>
                  </div>
                </MenuHandler>

                <MenuList className="justify-start">
                  <MenuItem
                    onClick={() => {
                      changeLanguage("en");
                    }}
                    className="flex items-start gap-2"
                  >
                    <img
                      src="/images/icons/uk.png"
                      className="rounded-full h-5 w-5"
                      alt=""
                    />
                    <Typography variant="small" className="font-semibold">
                      English
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      changeLanguage("zh");
                    }}
                    className="flex items-start gap-2"
                  >
                    <img
                      src="/images/icons/hk.png"
                      className="rounded-full h-5 w-5 "
                      alt=""
                    />
                    <Typography
                      variant="small"
                      className="font-medium font-reborn"
                    >
                      繁體中文
                    </Typography>
                  </MenuItem>
                </MenuList>
              </Menu>

              {/* ======== FB ======== */}
              <a
                href={chp_facebook_link}
                target="_blank"
                rel="noopener noreferrer"
                className="ms-1"
              >
                <span className="login-btn-primary ">
                  <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Unicons.UilFacebook width={16} />
                  </span>
                </span>

                <span className="login-btn-light ">
                  <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Unicons.UilFacebook width={16} />
                  </span>
                </span>
              </a>
              {/* ======== IG ======== */}

              <a
                href={chp_ig_link}
                target="_blank"
                rel="noopener noreferrer"
                className="ms-1"
              >
                <span className="login-btn-primary">
                  <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Unicons.UilInstagram width={16} />
                  </span>
                </span>

                <span className="login-btn-light">
                  <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Unicons.UilInstagram width={16} />
                  </span>
                </span>
              </a>

              {/* ======== WHATSAPP ======== */}
              <a
                href={chp_whatsapp_link}
                target="_blank"
                rel="noopener noreferrer"
                className="ms-1"
              >
                <span className="login-btn-primary ">
                  <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Unicons.UilWhatsapp width={16} />
                  </span>
                </span>

                <span className="login-btn-light ">
                  <span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Unicons.UilWhatsapp width={16} />
                  </span>
                </span>
              </a>

              <span
                className=" collapse-btn inline-flex items-center ms-2 text-dark dark:text-white  lg_992:hidden"
                onClick={openDrawer}
              >
                <i className="mdi mdi-menu text-[24px] lg_992:hidden ml-1"></i>
              </span>
              <Drawer overlay={false} open={open} onClose={closeDrawer}>
                <div className="mb-2 flex items-center justify-between p-4">
                  <Typography
                    // variant="h5"
                    className={
                      i18n.resolvedLanguage === "en"
                        ? "text-2xl"
                        : "text-2xl font-reborn"
                    }
                  >
                    {t("navBar.menu")}
                  </Typography>
                  <IconButton
                    variant="text"
                    color="blue-gray"
                    onClick={closeDrawer}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </IconButton>
                </div>

                <List
                  className={
                    i18n.resolvedLanguage === "en"
                      ? "nav-link"
                      : "nav-link font-reborn text-xl"
                  }
                >
                  {/* =====  About ===== */}
                  <Link to="/#about" id="aboutButton" onClick={closeDrawer}>
                    <ListItem>
                      <ListItemPrefix>
                        <IoPersonCircleSharp className="h-6 w-6" />
                      </ListItemPrefix>
                      <Typography className="font-medium ">
                        {t("navBar.about")}
                      </Typography>
                    </ListItem>
                  </Link>
                  {/* =====  Service ===== */}
                  <Link
                    to="/#service"
                    id="servicesButton"
                    onClick={closeDrawer}
                  >
                    <ListItem>
                      <ListItemPrefix>
                        <MdArticle className="h-6 w-6" />
                      </ListItemPrefix>
                      <Typography className="font-medium ">
                        {t("navBar.services")}
                      </Typography>
                    </ListItem>
                  </Link>

                  {/* =====  project ===== */}
                  {/* <Link
                    to="/#project"
                    id="projectsButton"
                    onClick={closeDrawer}
                  >
                    <ListItem>
                      <ListItemPrefix>
                        <MdWork className="h-6 w-6" />
                      </ListItemPrefix>
                      <Typography className="font-medium ">
                        {t("navBar.projects")}
                      </Typography>
                    </ListItem>
                  </Link> */}

                  {/* ===== [moblie] self-test ===== */}
                  {i18n.resolvedLanguage === "zh" && (
                    <Accordion
                      className="nav-self-test"
                      open={openSelfTest === 2}
                      // icon={
                      //   <FaChevronCircleDown
                      //     strokeWidth={2.5}
                      //     className={`mx-auto h-4 w-4 transition-transform ${
                      //       open === 2 ? "rotate-180" : ""
                      //     }`}
                      //   />
                      // }
                    >
                      <ListItem className="p-0" selected={open === 2}>
                        <AccordionHeader
                          onClick={() => handleOpenSelfTest(2)}
                          className="border-b-0"
                        >
                          <ListItem className="nav-self-test-list-item">
                            <div>
                              <ListItemPrefix>
                                <FaFileSignature
                                  className="h-6 w-6"
                                  style={{ marginLeft: "2px" }}
                                />
                              </ListItemPrefix>
                            </div>

                            <Typography className="font-medium p-0 ">
                              {t("navBar.self-test")}
                            </Typography>
                          </ListItem>
                        </AccordionHeader>
                      </ListItem>
                      <AccordionBody className="nav-self-test-body">
                        <List className="nav-self-test-body-list">
                          <Link to="/psychological-distress-test">
                            <ListItem>
                              <ListItemPrefix>
                                <FaHeartCircleExclamation
                                  strokeWidth={3}
                                  className="h-6 w-6"
                                />
                              </ListItemPrefix>

                              {t("navBar.k10")}
                            </ListItem>
                          </Link>
                          <Link to="/assessment">
                            <ListItem className="nav-self-test-body-list-item-Partnership-Assessment">
                              <ListItemPrefix>
                                <FaVenusMars
                                  strokeWidth={3}
                                  className="h-6 w-6"
                                />
                              </ListItemPrefix>

                              {t("navBar.Partnership-Assessment")}
                            </ListItem>
                          </Link>
                        </List>
                      </AccordionBody>
                    </Accordion>
                  )}
                  {/* =====  blog ===== */}

                  <a
                    href={process.env.REACT_APP_BLOG_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeDrawer}
                  >
                    <ListItem>
                      <ListItemPrefix>
                        <FaPenClip className="h-6 w-6" />
                      </ListItemPrefix>
                      <Typography className="font-medium ">
                        {t("navBar.blogs")}
                      </Typography>
                    </ListItem>
                  </a>

                  {/* =====  contactMe ===== */}
                  <Link
                    to="/#contactMe"
                    id="contactMeButton"
                    onClick={closeDrawer}
                  >
                    <ListItem>
                      <ListItemPrefix>
                        <FaPhoneAlt className="h-6 w-6" />
                      </ListItemPrefix>
                      <Typography className="font-medium ">
                        {t("navBar.contact")}
                      </Typography>
                    </ListItem>
                  </Link>

                  {/* =====  contactMe ===== */}
                  <Link to="/album" id="albumButton" onClick={closeDrawer}>
                    <ListItem>
                      <ListItemPrefix>
                        <IoMdPhotos className="h-6 w-6" />
                      </ListItemPrefix>
                      <Typography className="font-medium ">
                        {t("navBar.album")}
                      </Typography>
                    </ListItem>
                  </Link>

                  {/* ========================================= */}
                </List>
              </Drawer>
            </li>
          </ul>
        </div>

        {/* <!-- Navbar Manu --> */}
        <div
          className="navigation lg_992:order-1 lg_992:flex hidden ms-auto"
          id="menu-collapse"
        >
          <ul className="navbar-nav" id="navbar-navlist">
            <li className="nav-item">
              <Link
                to="/#about"
                id="aboutButton"
                className={
                  i18n.resolvedLanguage === "en"
                    ? "nav-link"
                    : "  nav-link font-reborn text-lg"
                }
              >
                {t("navBar.about")}
              </Link>
            </li>

            {/* ================= */}
            <li className="nav-item">
              <Link
                to="/#service"
                id="servicesButton"
                className={
                  i18n.resolvedLanguage === "en"
                    ? "nav-link"
                    : "  nav-link font-reborn text-lg"
                }
              >
                {t("navBar.services")}
              </Link>
            </li>

            {/* ======= [PC] self-test ========== */}
            {i18n.resolvedLanguage === "zh" && (
              <li className="nav-item">
                <Menu
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                >
                  <MenuHandler>
                    <p
                      className={
                        i18n.resolvedLanguage === "en"
                          ? "nav-link"
                          : "  nav-link font-reborn text-lg"
                      }
                    >
                      {t("navBar.self-test")}
                    </p>
                  </MenuHandler>
                  <MenuList
                    className={
                      i18n.resolvedLanguage === "en"
                        ? "nav-link"
                        : "  nav-link font-reborn text-lg"
                    }
                  >
                    <MenuItem>
                      <Link to="/psychological-distress-test">
                        {t("navBar.k10")}
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link to="/assessment">
                        {t("navBar.Partnership-Assessment")}
                      </Link>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </li>
            )}

            {/* ======= [PC] Blog ========== */}
            <li className="nav-item">
              <a
                href={process.env.REACT_APP_BLOG_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  i18n.resolvedLanguage === "en"
                    ? "nav-link"
                    : "  nav-link font-reborn text-lg"
                }
                id="blogsButton"
              >
                {t("navBar.blogs")}
              </a>
            </li>
            {/* ================= */}
            <li className="nav-item">
              <Link
                to="/#contactMe"
                id="contactMeButton"
                className={
                  i18n.resolvedLanguage === "en"
                    ? "nav-link"
                    : "  nav-link font-reborn text-lg"
                }
              >
                {t("navBar.contact")}
              </Link>
            </li>
            {/* ================= */}
            <li className="nav-item">
              <Link
                to="/album"
                id="albumButton"
                className={
                  i18n.resolvedLanguage === "en"
                    ? "nav-link"
                    : "  nav-link font-reborn text-lg"
                }
              >
                {t("navBar.album")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
