import React, { useRef, useEffect } from "react";
// import { expertiseData } from "../Data/data";
import * as Icon from "react-feather";
import CountUp from "react-countup";
import { Link, useLocation } from "react-router-dom";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
// hook
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const expertiseData = [
    {
      title: t("about.expertise.skill.1"),
      Icon: Icon.Hash,
    },
    {
      title: t("about.expertise.skill.2"),
      Icon: Icon.Hash,
    },
    {
      title: t("about.expertise.skill.3"),
      Icon: Icon.Hash,
    },
    {
      // title: "Prepare/Enrich Practitioner (Pre- and post-marital counselor)",
      title: t("about.expertise.skill.4"),
      Icon: Icon.Hash,
    },
    {
      title: t("about.expertise.skill.5"),
      Icon: Icon.Hash,
    },
    {
      title: t("about.expertise.skill.6"),
      Icon: Icon.Hash,
    },
    {
      title: t("about.expertise.skill.7"),
      Icon: Icon.Hash,
    },
    {
      title: t("about.expertise.skill.8"),
      Icon: Icon.Hash,
    },
  ];

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <section className="relative md:py-24 py-16 bg-green-400 " id="about">
      <div className="brush_1" data-wow-duration="1s">
        <img src="/images/bg/brushes/about/1.png" alt="1" />
      </div>
      <div className="brush_2" data-wow-duration="1s">
        <img src="/images/bg/brushes/about/2.png" alt="2" />
      </div>
      <div className="container">
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
          <div className="lg:col-span-5 lg:px-8">
            <div className="relative">
              <div className="absolute inset-0 border dark:border-gray-800 rounded-full -mt-[10px] -ms-3 h-[100%] w-[100%] -z-1"></div>

              <img
                src="/images/ab-kaka.png"
                className="w-full rounded-full shadow-md shadow-gray-200 dark:shadow-gray-800 bg-green-500"
                alt=""
              />
              {/* {i18n.resolvedLanguage === "zh" && (
                <div className="absolute lg:bottom-20 md:bottom-10 bottom-6 ltr:lg:-right-16 rtl:lg:-left-16 ltr:md:-right-8 rtl:md:-left-8 ltr:right-0 rtl:left-0 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
                  <h6 className="font-reborn text-xl">
                    {" "}
                    {t("about.Youth-Work")}
                  </h6>
                  <span className="text-2xl font-medium text-amber-500 mb-0">
                    <span
                      className="counter-value font-bold font-reborn"
                      data-target="7"
                    >
                      <CountUp
                        start={10}
                        className="font-reborn"
                        end={20}
                        duration={9}
                      />
                      +
                    </span>
                  </span>
                  <span className="font-reborn  text-lg text-slate-400">
                    {t("about.Year")}
                    {t("about.Experience")}
                  </span>
                </div>
              )}

              {i18n.resolvedLanguage === "en" && (
                <div className="absolute lg:bottom-20 md:bottom-10 bottom-6 ltr:lg:-right-16 rtl:lg:-left-16 ltr:md:-right-8 rtl:md:-left-8 ltr:right-0 rtl:left-0 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
                  <h6 className="font-semibold"> {t("about.Youth-Work")}</h6>
                  <span className="text-2xl font-medium text-amber-500 mb-0">
                    <span className="counter-value font-bold" data-target="7">
                      <CountUp
                        start={10}
                        className="counter-value "
                        end={20}
                        duration={9}
                      />
                    </span>
                    +
                  </span>
                  <span className="text-sm text-slate-400">
                    {t("about.Year")} <br />
                    {t("about.Experience")}
                  </span>
                </div>
              )} */}
            </div>
          </div>

          <div className="lg:col-span-7">
            <div className="lg:ms-5">
              {/* <h3
                className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
                  i18n.resolvedLanguage === "zh"
                    ? "font-reborn text-xl "
                    : "font-semibold"
                }`}
              >
                {t("about.title")}
              </h3> */}

              <p
                className={`text-slate-400 max-w-xl text-[15px] ${
                  i18n.resolvedLanguage === "zh" ? "font-reborn text-xl " : ""
                }`}
              >
                {t("about.p1")}
              </p>
              <p
                className={`text-slate-400 max-w-xl text-[15px] mt-6  ${
                  i18n.resolvedLanguage === "zh" ? "font-reborn text-xl " : ""
                }`}
              >
                {t("about.p2")}
              </p>
              <p
                className={`text-slate-400 max-w-xl text-[15px]  ${
                  i18n.resolvedLanguage === "zh" ? "font-reborn text-xl " : ""
                }`}
              >
                {t("about.p2-1")}
              </p>
              <p
                className={`text-slate-400 max-w-xl text-[15px]  ${
                  i18n.resolvedLanguage === "zh" ? "font-reborn text-xl " : ""
                }`}
              >
                {t("about.p2-2")}
              </p>
              <p
                className={`text-slate-400 max-w-xl text-[15px] mt-6  ${
                  i18n.resolvedLanguage === "zh" ? "font-reborn text-xl " : ""
                }`}
              >
                {t("about.p3")}
              </p>
            </div>
          </div>
          <Element id="about" />
        </div>
      </div>

      {/* <div className="container md:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3
            className={`mb-6 md:text-2xl  md:leading-normal leading-normal  ${
              i18n.resolvedLanguage === "zh"
                ? "font-reborn text-2xl"
                : "font-semibold text-xl"
            }`}
          >
            {t("about.expertise.title")}
          </h3>

      
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
          {expertiseData.map((item, index) => {
            const Icon = item.Icon;
            return (
              <div
                className="flex  shadow shadow-gray-200 dark:shadow-gray-800 dark:hover:shadow-gray-700 items-center p-3 rounded-lg bg-white dark:bg-slate-900"
                key={index}
              >
                <div className="flex items-center justify-center h-[25px] max-w-[35px] -rotate-45 bg-amber-500/10 group-hover:bg-amber-500 text-amber-500 group-hover:text-white text-center rounded-xl me-5 transition-all duration-500">
                  <div className="rotate-45">
                    <Icon className="h-5 w-5 " />
                  </div>
                </div>
                <div className="flex-1">
                  <h4
                    className={`mb-0 text-[17px] ${
                      i18n.resolvedLanguage === "zh"
                        ? "font-reborn "
                        : "font-medium"
                    }
             `}
                  >
                    {item.title}
                  </h4>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
    </section>
  );
}
