import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const ContactForm = ({
  form,
  formValues,
  setFormValues,
  sendEmail,
  errors, // Receive errors as a prop
}) => {
  const { t, i18n } = useTranslation();

  return (
    <form ref={form} className="font-reborn p-6 mt-3">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3
          className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal ${
            i18n.resolvedLanguage === "zh" ? "font-reborn  " : "font-semibold"
          }  `}
        >
          {t("contact.title")}
        </h3>
      </div>
      <div className="grid lg:grid-cols-12 lg:gap-5">
        <div className="lg:col-span-6 mb-5">
          <input
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            key="user_name"
            name="user_name"
            id="name"
            type="text"
            className={`${
              i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
            } form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
            placeholder={t("contact.name")}
          />
        </div>

        <div className="lg:col-span-6 mb-5 ">
          <input
            value={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            key="user_email"
            name="user_email"
            id="email"
            type="email"
            className={`${
              i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
            } form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
            placeholder={t("contact.email")}
          />
        </div>
      </div>
      <div className="grid grid-cols-1">
        <div className="mb-5">
          <input
            value={formValues.subject}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                subject: e.target.value,
              })
            }
            key="subject"
            name="subject"
            id="subject"
            className={`${
              i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
            } form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
            placeholder={t("contact.subject")}
          />
        </div>
        <div className="mb-5">
          <textarea
            value={formValues.message}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                message: e.target.value,
              })
            }
            key="message"
            name="message"
            id="comments"
            className={`${
              i18n.resolvedLanguage === "zh" ? "font-reborn  " : ""
            } 
            form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]`}
            // form-input w-full py-2 px-3 border dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]
            placeholder={t("contact.message")}
          ></textarea>
        </div>
      </div>

      {errors && errors.name && errors.name._errors.length > 0 && (
        <p style={{ color: "red", fontWeight: "bold", fontSize: "0.875rem" }}>
          {"*" + t("errors.name")}
        </p>
      )}
      {errors && errors.email && errors.email._errors.length > 0 && (
        <p style={{ color: "red", fontWeight: "bold", fontSize: "0.875rem" }}>
          {"*" + t("errors.email")}
        </p>
      )}
      {errors && errors.subject && errors.subject._errors.length > 0 && (
        <p style={{ color: "red", fontWeight: "bold", fontSize: "0.875rem" }}>
          {"*" + t("errors.subject")}
        </p>
      )}
      {errors && errors.message && errors.message._errors.length > 0 && (
        <p style={{ color: "red", fontWeight: "bold", fontSize: "0.875rem" }}>
          {"*" + t("errors.message")}
        </p>
      )}
    </form>
  );
};

export default ContactForm;
