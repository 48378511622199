import React, { useEffect, useLocation } from "react";
import Navbar from "../components/Navbar";
import HeroOne from "../components/HeroOne";

import AboutUs from "../components/AboutUs";
import Services from "../components/Service";

import Projects from "../components/Projects";
import Blogs from "../components/Blogs";
import GetInTouch from "../components/GetInTouch";

import Clients from "../components/Clients";

import "tw-elements-react/dist/css/tw-elements-react.min.css";
import { Analytics } from "@vercel/analytics/react";
export default function Index() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
  }, []);

  return (
    <div>
      {/* public/images/slider/1.png */}
      {/* <Example /> */}

      <Navbar />
      <HeroOne />
      {/* <HeroOne /> */}

      <AboutUs />

      <Services />
      {/* <CTABanner/>
      <Experience/> */}
      {/* <Experience /> */}
      {/* <Projects /> */}
      <Clients />
      <Blogs />

      <GetInTouch />
      <Analytics />
      {/* <Footer /> */}
    </div>
  );
}
