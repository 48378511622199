import React from "react";
import { Link } from "react-router-dom";

import TinySlider from "tiny-slider-react";
import "../../node_modules/tiny-slider/dist/tiny-slider.css";

// import { all_review, review } from "../Data/data";
// hook
import { useTranslation } from "react-i18next";

const settings = {
  lazyload: true,
  controls: false,
  mouseDrag: true,
  loop: true,
  // rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 600,
  gutter: 12,
  responsive: {
    992: {
      items: 3,
    },

    767: {
      items: 2,
    },

    320: {
      items: 1,
    },
  },
};
export default function Clients() {
  const { t, i18n } = useTranslation();
  const all_review = [
    {
      name: "Mr Wong",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: t("client.reviews.1"),
    },
    {
      name: "Miss Cheung",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.2"),
    },
    {
      name: " Primary School Teacher",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: t("client.reviews.3"),
    },
    {
      name: "Parent",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.4"),
    },

    {
      name: "Elderly",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.5"),
    },
    {
      name: "Corporate Staff",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.6"),
    },

    {
      name: "Pain Patient",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.7"),
    },
    {
      name: "Mr Kwok (Yoga Student)",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: t("client.reviews.8"),
    },
    {
      name: "Mr Chong",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: t("client.reviews.9"),
    },
    {
      name: " Mr Mak",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: t("client.reviews.10"),
    },

    {
      name: "Miss Mok (SFT)",
      designation: "Manager",

      desc: t("client.reviews.11"),
    },
    {
      name: "Miss Tse (SFT)",
      designation: "Manager",

      desc: t("client.reviews.12"),
    },
    {
      name: "Mr Lo (SFT)",
      designation: "Manager",

      desc: t("client.reviews.13"),
    },
  ];

  const all_review_zh = [
    {
      name: "王先生",
      designation: "一般輔導",
      image: "/images/client/counseling_1.jpg",
      desc: "有時生活片段太多太瑣碎，自己整合唔到。你嘅整合能力好強，令我更加清晰成件事，自己嘅位置。最勁係你欣賞得好具體，令我都好欣賞到自己！",
    },
    {
      name: "張小姐",
      designation: "一般輔導",
      image: "/images/client/counseling_3.jpg",
      desc: "你就好似一面鏡咁講俾我聽自己嘅情況，令我清晰咁去諗將來想期待嘅生活，再一步步咁實踐。",
    },
    {
      name: "小學老師 (禪繞畫)",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: "冇諗過過度活躍嘅學生都可以坐得低咁耐，仲要勁有創意，感覺到佢自信高咗。",
    },
    {
      name: "家長 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "好難得有自己時間，好集中係自己度，唔使再諗湊仔嘅嘢！",
    },

    {
      name: "長者 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "以前揸開刀，咁耐以來第一次揸筆! 原來好簡單，劃劃下就上手，有時畫到停唔到！",
    },
    {
      name: "公司員工 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "平日番工好大壓力。呢幾日開會專注唔到，點知劃劃下反而專注繼續開會!",
    },

    {
      name: "痛症病人 (禪繞畫學員)",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "之前關節痛咗好耐，畫嘅時候即刻感覺唔到痛，好神奇！",
    },
    {
      name: "郭先生 (瑜伽學員)",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: "拉嘅過程有啲辛苦，但導師就著我嘅情況調整，慢慢適應咗，完成咗之後好有滿足感同埋瞓得好好！",
    },
    {
      name: "莊先生",
      designation: "Manager",
      image: "/images/client/counseling_3.jpg",
      desc: "一直都唔知道原來婚前輔導嘅重要性。呢個報告好全面，加埋輔導之後將一直以來都埋咗喺心裏面嘅不滿都講咗出嚟，再諗之後點面對。慶幸喺結婚前清一次，多謝未婚妻提議黎見輔導！",
    },
    {
      name: "麥先生",
      designation: "Manager",
      image: "/images/client/counseling_1.jpg",
      desc: "結婚之後一直都儲埋好多不滿，直到呢件事發生！一直都原諒唔到。經過呢次輔導，我明白到點解我咁執着。而佢亦都真正理解我嘅諗法，同埋反思到自己嘅問題！我哋而家關係開始一步步改變。",
    },

    {
      name: "莫小姐(SFT學員)",
      designation: "一般輔導",
      image: "/images/client/counseling_1.jpg",
      desc: "之前一直對「尋解導向模式」誤解，原來有很多細緻技巧及概念要留意，才能帶出當中精髓。透過這個培訓中的角色扮演練習，讓我更精準掌握技巧。",
    },
    {
      name: "謝小姐(SFT學員)",
      designation: "一般輔導",
      image: "/images/client/counseling_3.jpg",
      desc: "這個輔導模式的確很精準，做個案時間簡短了很多，效率更高！",
    },
    {
      name: "盧先生(SFT學員)",
      designation: "一般輔導",
      image: "/images/client/counseling_3.jpg",
      desc: "培訓非常有趣及實際地了解技巧。導師能指出重點及提供不同練習，亦能即時回應，讓我反思很多。",
    },
  ];

  return (
    <section
      className="relative md:py16 py-16 bg-green-400 dark:bg-slate-800"
      id="testi"
    >
      <div className="brush_partners_1" data-wow-duration="1s">
        <img src="/images/bg/brushes/partners/1.png" alt="1" />
      </div>
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3
            className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal 
           ${i18n.resolvedLanguage === "zh" ? "font-reborn" : "font-semibold"}
          
          `}
          >
            {t("client.title")}
          </h3>

          {/* <p
            className={`text-slate-400 max-w-xl mx-auto text-[15px]   ${
              i18n.resolvedLanguage === "zh" ? "font-reborn" : ""
            }`}
          >
            {t("client.p1")}
          </p> */}
        </div>
        {i18n.resolvedLanguage === "zh" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review_zh.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-1 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>

                        <p className="text-slate-400 text-[18px] font-reborn">
                          {item.desc}
                        </p>

                        <p className="text-slate-400 text-[18px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none  text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}

        {i18n.resolvedLanguage === "en" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>
                        <p className="text-slate-400 text-[15px]">
                          {item.desc}
                        </p>
                        <p className="text-slate-400 text-[15px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
