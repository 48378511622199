import React from "react";
import { Link } from "react-router-dom";

import TinySlider from "tiny-slider-react";

import "../../../node_modules/tiny-slider/dist/tiny-slider.css";

// hook
import { useTranslation, Trans } from "react-i18next";

const settings = {
  lazyload: true,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 10000,
  navPosition: "bottom",
  speed: 400,
  gutter: 12,
  responsive: {
    1530: {
      items: 2,
    },
    992: {
      items: 2,
    },

    767: {
      items: 1,
    },

    320: {
      items: 1,
    },
  },
};
export default function CounselingTestimonial() {
  const { t, i18n } = useTranslation();

  const all_review = [
    {
      name: "Miss Mok",
      designation: "Manager",

      desc: t("client.reviews.11"),
    },
    {
      name: "Miss Tse",
      designation: "Manager",

      desc: t("client.reviews.12"),
    },
    {
      name: "Mr Lo",
      designation: "Manager",

      desc: t("client.reviews.13"),
    },
  ];

  const all_review_zh = [
    {
      name: "莫小姐",
      designation: "一般輔導",
      image: "/images/client/counseling_1.jpg",
      desc: "之前一直對「尋解導向模式」誤解，原來有很多細緻技巧及概念要留意，才能帶出當中精髓。透過這個培訓中的角色扮演練習，讓我更精準掌握技巧。",
    },
    {
      name: "謝小姐",
      designation: "一般輔導",
      image: "/images/client/counseling_3.jpg",
      desc: "這個輔導模式的確很精準，做個案時間簡短了很多，效率更高！",
    },
    {
      name: "盧先生",
      designation: "一般輔導",
      image: "/images/client/counseling_3.jpg",
      desc: "培訓非常有趣及實際地了解技巧。導師能指出重點及提供不同練習，亦能即時回應，讓我反思很多。",
    },
  ];

  return (
    <section className="relative py-16" id="testi">
      <div className="container">
        <div className="grid grid-cols-1 text-center">
          <h3
            className={`mb-6 md:text-2xl text-xl md:leading-normal leading-normal
           ${i18n.resolvedLanguage === "zh" ? "font-reborn" : "font-semibold"}
          `}
          >
            {t("client.title")}
          </h3>
        </div>
        {i18n.resolvedLanguage === "zh" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review_zh.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-1 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>

                        <p className="text-slate-400 text-[18px] font-reborn">
                          {item.desc}
                        </p>

                        <p className="text-slate-400 text-[18px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none  text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}

        {i18n.resolvedLanguage === "en" && (
          <div className="grid relative grid-cols-1 mt-8">
            <div className="tiny-three-item">
              <TinySlider settings={settings}>
                {all_review.map((item, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="customer-testi">
                      <div className="content relative rounded shadow shadow-gray-200 dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                        <i className="mdi mdi-format-quote-open mdi-48px text-amber-500"></i>
                        <p className="text-slate-400 text-[15px]">
                          {item.desc}
                        </p>
                        <p className="text-slate-400 text-[15px] font-reborn mt-3">
                          {item.name}
                        </p>
                        <ul className="list-none text-amber-400">
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
