import GhostContentAPI from "@tryghost/content-api";

const api = new GhostContentAPI({
  url: "https://blog.chphk.com",
  key: "569f237b8b983e5de3d8e0e9df",
  version: "v5.0",
});

// fetch 5 posts, including related tags and authors
// api.posts
//   .browse({ limit: 5, include: "tags,authors" })
//   .then((posts) => {
//     posts.forEach((post) => {
//       console.log(post.title);
//     });
//   })
//   .catch((err) => {
//     console.error(err);
//   });

export async function getPosts() {
  return await api.posts
    .browse({ limit: 3, include: "tags,authors" })
    .then((posts) => {
      const postArray = [];
      posts.forEach((post) => {
        // Modify the 'feature_image' property
        const modifiedPost = {
          ...post, // Copy all existing properties from the original post object
          feature_image: post.feature_image.replace(
            "https://blog.chphk.com/content/images/",
            "https://blog.chphk.com/content/images/size/w600/format/webp/"
          ),
        };

        postArray.push(modifiedPost);
      });
      // Return the array of posts
      return postArray;
    })
    .catch((err) => {
      console.error(err);
    });
}
